import './splash.css';
import Logo from '../../Assets/Images/CompanyLogo.png';
import MandelbrotPlot from './MandlebrotPlot';

const Splash = () => {
    return (<div className="launch-page">
        <div className="mandelbrot-plot">
            <MandelbrotPlot />
        </div>
        <a href="about" className="launch-title">
            <h1>Martingale Stochastic Process LLC</h1>
        </a>

        <a href="about" className="launch-logo-link">
            <img
                className="logo"
                src={Logo}
                alt="Company logo."
                height="300"
                width="300"
            />
            <div>Click Here</div>
        </a>

    </div>);
}

export default Splash;