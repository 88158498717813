import React, { useRef, useEffect, useState } from "react";

const MandelbrotPlot = () => {
  const canvasRef = useRef(null);
  const workerRef = useRef(null);
  const [state, setState] = useState({
    xCenter: -0.7436438870371587, // Seahorse Valley
    yCenter: 0.13182590420531197,
    zoom: 1,
    maxIterations: 100,
  });

  const [isBusy, setIsBusy] = useState(false); // Prevent overlapping calculations

  // Create and persist worker on mount
  useEffect(() => {
    const workerScript = createWorkerScript();
    const workerBlob = new Blob([workerScript], { type: "application/javascript" });
    workerRef.current = new Worker(URL.createObjectURL(workerBlob));

    // Handle Worker messages
    workerRef.current.onmessage = ({ data }) => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");

      const pixels = new Uint8ClampedArray(data);
      const imageData = new ImageData(pixels, canvas.width, canvas.height);
      ctx.putImageData(imageData, 0, 0);

      setIsBusy(false); // Worker is free now
    };

    return () => {
      workerRef.current.terminate();
      workerRef.current = null;
    };
  }, []);

  // Render fractal when state changes
  useEffect(() => {
    if (!workerRef.current || isBusy) return;

    setIsBusy(true);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const { xCenter, yCenter, zoom, maxIterations } = state;
    const width = canvas.width;
    const height = canvas.height;

    // Compute complex-plane boundaries
    const size = 4 / zoom;
    const xMin = xCenter - size / 2;
    const xMax = xCenter + size / 2;
    const yMin = yCenter - size / 2;
    const yMax = yCenter + size / 2;

    workerRef.current.postMessage({ width, height, xMin, xMax, yMin, yMax, maxIterations });
  }, [state]);

  // Smooth zoom effect
  useEffect(() => {
    const zoomFactor = 1.01; // ~2% zoom per step
    const zoomSpeed = 25; // 100ms interval for smoother animation

    const zoomIn = () => {
      if (!isBusy) {
        setState((prev) => ({
          ...prev,
          zoom: prev.zoom * zoomFactor,
          maxIterations: prev.maxIterations + 2, // Gradually increase detail
        }));
      }
    };

    const intervalId = setInterval(zoomIn, zoomSpeed);
    return () => clearInterval(intervalId);
  }, [isBusy]);

  return (
    <div>
      <canvas ref={canvasRef} width={800} height={600}></canvas>
    </div>
  );
};

export default MandelbrotPlot;

/** Returns the text of the inline Web Worker script */
const createWorkerScript = () => `
  self.onmessage = ({ data }) => {
    const { width, height, xMin, xMax, yMin, yMax, maxIterations } = data;
    const pixels = new Uint8ClampedArray(width * height * 4);

    const mapRange = (value, inMin, inMax, outMin, outMax) => 
      ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

    for (let px = 0; px < width; px++) {
      for (let py = 0; py < height; py++) {
        const x0 = mapRange(px, 0, width, xMin, xMax);
        const y0 = mapRange(py, 0, height, yMin, yMax);
        let x = 0, y = 0, iteration = 0;

        while (x*x + y*y <= 4 && iteration < maxIterations) {
          const xTemp = x*x - y*y + x0;
          y = 2 * x * y + y0;
          x = xTemp;
          iteration++;
        }

        const idx = (py * width + px) * 4;
        const shade = Math.sqrt(iteration / maxIterations) * 255;
        pixels[idx + 0] = shade;
        pixels[idx + 1] = shade * 0.7;
        pixels[idx + 2] = shade * 0.3;
        pixels[idx + 3] = 255;
      }
    }

    self.postMessage(pixels.buffer, [pixels.buffer]);
  };
`;
