import React, { useEffect, useState } from "react";

const BrownianAscii = ({ width = 20, steps = 50, speed = 200 }) => {
  const lines = 7;
  
  useEffect(() => {
    let positionY = Math.floor(lines / 2);
    let timeStep = 0;
    let trail = Array.from({ length: lines }, () => Array(width).fill(" "));
    
    const printFrame = () => {
      // Clear last column for the new frame
      for (let i = 0; i < lines; i++) {
        trail[i][timeStep] = " ";
      }
      
      // Place the moving dot along the horizontal time axis and leave a trail
      trail[positionY][timeStep] = "●";
      
      // Print ASCII to console
      console.clear();
      console.log(trail.map(row => row.join(" ")).join("\n"));

      // Brownian Motion: Move up or down randomly
      positionY += Math.random() < 0.5 ? -1 : 1;
      
      // Keep within vertical bounds
      positionY = Math.max(0, Math.min(lines - 1, positionY));

      // Move time step forward
      timeStep++;
      if (timeStep >= width) {
        timeStep = 0; // Reset when reaching the end
        trail = Array.from({ length: lines }, () => Array(width).fill(" "));
      }
    };

    // Start animation
    const interval = setInterval(printFrame, speed);

    return () => clearInterval(interval);
  }, [width, steps, speed]);

  return <div>Check your console for ASCII Brownian Motion!</div>;
};

export default BrownianAscii;
